@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .footer {
    @apply absolute right-0 left-0 bg-white;
    @apply xl:bottom-9 xl:mx-8 xl:rounded-2rem xl:h-[14.375rem];
    @apply 2xl:bottom-16 2xl:mx-16 2xl:rounded-4xl 2xl:h-[28.25rem];
  }

  .columns {
    @apply flex flex-row;
    @apply xl:px-5 xl:py-5;
    @apply 2xl:px-9 2xl:py-9;
  }

  .imageContainer {
    @apply w-[28vw];
  }

  .image {
    @apply xl:h-[11.875rem] xl:bg-11.875rem;
    @apply 2xl:h-[23.75rem] 2xl:bg-23.75rem;
  }

  .rightColumn {
    @apply flex flex-col;
    @apply xl:mt-9 xl:ml-8;
    @apply 2xl:mt-18 2xl:ml-16;
  }

  .titleText {
    @apply font-bebas-book whitespace-nowrap;
    @apply xl:text-[6.7vw] xl:leading-normal xl:tracking-[0.2rem];
    @apply 2xl:text-[7.7vw] 2xl:leading-[10.25rem] 2xl:tracking-[0.41rem];
  }

  .subtitleText {
    @apply font-bebas-book;
    @apply xl:text-[3.5rem] xl:leading-[2.5rem] xl:tracking-[0.1rem];
    @apply 2xl:text-[6.25rem] 2xl:leading-[5.25rem] 2xl:tracking-[0.21rem];
  }

}

.qrImage {
  margin: auto;
}
@media (min-width: 900px) {
  .qrImage {
    height: 11.875rem;
    width: 11.875rem;
  }
}
@media (min-width: 1536px) {
  .qrImage {
    height: 23.75rem;
    width: 23.75rem;
  }
}

.image {
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 1;
}

.leftColumnFade {
  animation: leftColumnFade 30s linear forwards;
}


@keyframes leftColumnFade {
  47% {
    opacity: 1;
  }
  49% {
    opacity: 0;
  }
  51% {
    opacity: 0;
  }
  53% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.rightColumnFade {
  opacity: 1;
  animation: rightColumnFade 30s linear forwards;
}

@keyframes rightColumnFade {
  48% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  53% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.footerSlideBottom {
  transform: translateY(200%);
  animation: bottomSlide 0.7s linear 1s forwards;
}

@keyframes bottomSlide {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

.footerSlideRight {
  transform: translateX(200%);
  animation: rightSlide 0.7s ease-out 0.3s forwards;
}

@keyframes rightSlide {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0);
  }
}
